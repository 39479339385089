// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios
import axios from '@src/service/axios';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// ** loader import
import showLoader from '@components/loader';

export const getSubTopic = createAsyncThunk('subTopicSlice/getSubTopic', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get('/sub-topics?isBussiness=0', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const getSubTopicById = createAsyncThunk('subTopicSlice/getSubTopicById', async (id, { rejectWithValue }) => {
    try {
        let response = await axios.get('/sub-topics/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateSubTopicsPriority = createAsyncThunk('subTopicSlice/updateSubTopicsPriority', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post(
            '/sub-topics/update-priority/' + data?.id,
            { subTopicsIds: data?.data },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        toast.success(response?.data?.message || 'Topics Priority updated successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const subTopicSlice = createSlice({
    name: 'subTopicSlice',
    initialState: {
        data: [],
        selectedData: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getSubTopic.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSubTopicById.fulfilled, (state, action) => {
            state.selectedData = action?.payload;
            return;
        });
    }
});

export default subTopicSlice.reducer;
