// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// API Imports
import axios from '@src/service/axios';

// import { getPermissionsByToken } from '../service';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

// secure ls
import { secureGet, secureSet } from '@src/service/secureLs';

const initialPermission = () => {
    const data = secureGet('_accessible');
    if (data) return data;
    return [];
};

const getPermissionsByToken = async () => {
    return await axios.get('/permission', {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export const getPermission = createAsyncThunk('permission/getPermission', async () => {
    return getPermissionsByToken()
        .then((res) => {
            secureSet('_accessible', res.data);
            return res.data;
        })
        .catch((err) => {
            // toast.error(err.response?.data?.message || 'Something went wrong!');
            return [];
        });
});

export const permissionSlice = createSlice({
    name: 'permission',
    initialState: {
        permission: initialPermission()
    },
    reducers: {
        handlePermissionChange: (state, action) => {
            state.permission = action.payload;
        },
        removePermission: (state, action) => {
            state.permission = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPermission.fulfilled, (state, action) => {
            permissionSlice.caseReducers.handlePermissionChange(state, action);
        });
    }
});

export const { handlePermissionChange, removePermission } = permissionSlice.actions;

export default permissionSlice.reducer;
