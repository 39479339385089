// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import tabs from './tabs';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import business from '../views/Business/store';
import portalSetting from './portalSetting';
import course from '../views/ManageCourse/Course/store';
import topic from '../views/ManageCourse/Topics/store';
import subTopic from '../views/ManageCourse/SubTopics/store';

const rootReducer = {
    auth,
    navbar,
    layout,
    tabs,
    permission,
    users,
    roles,
    business,
    portalSetting,
    course,
    topic,
    subTopic
};

export default rootReducer;
