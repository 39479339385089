// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** ThemeConfig
import themeConfig from '../configs/themeConfig';

import SecureLS from 'secure-ls';

export const secureLs = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: 'vuexy-react-admin-dashboard',
    isCompression: false
});

const initialActiveTab = () => {
    const item = window.localStorage.getItem('activeTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.activeTab;
};

export const tabSlice = createSlice({
    name: 'tabSliceData',
    initialState: {
        activeTab: initialActiveTab()
    },
    reducers: {
        handleActiveTab: (state, action) => {
            state.activeTab = action.payload;
            localStorage.setItem('activeTab', JSON.stringify(action.payload));
        },
        removeTabs: (state, action) => {
            state.activeTab = '1';
            localStorage.setItem('activeTab', '"1"');
        }
    }
});

export const { handleActiveTab, removeTabs } = tabSlice.actions;

export default tabSlice.reducer;
