// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios
import axios from '@src/service/axios';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// ** loader import
import showLoader from '@components/loader';

export const getBusiness = createAsyncThunk('businessSlice/getBusiness', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get('/business', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const getBusinessById = createAsyncThunk('businessSlice/getBusinessById', async (id, { rejectWithValue }) => {
    try {
        let response = await axios.get('/business/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const createBusiness = createAsyncThunk('businessSlice/createBusiness', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.post('/business', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        toast.success(response?.data?.message || 'Business created successfully.');
        showLoader(false);

        await dispatch(getBusiness());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        showLoader(false);
        return rejectWithValue(err);
    }
});

export const createBusinessStep1 = createAsyncThunk('businessSlice/createBusinessStep1', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.post('/business/step-1', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        // toast.success(response?.data?.message || 'Business created successfully.');
        showLoader(false);

        // await dispatch(getBusiness());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        showLoader(false);
        return rejectWithValue(err);
    }
});
export const createBusinessStep2 = createAsyncThunk('businessSlice/createBusinessStep2', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.post('/business/subscription/' + data?.businessId, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        // toast.success(response?.data?.message || 'Business created successfully.');
        showLoader(false);

        // await dispatch(getBusiness());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        showLoader(false);
        return rejectWithValue(err);
    }
});

export const mockBusinessLogin = createAsyncThunk('businessSlice/mockBusinessLogin', async (id, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.post('/business/mock-login/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        showLoader(false);
        return rejectWithValue(err);
    }
});

export const updateBusiness = createAsyncThunk('businessSlice/updateBusiness', async (data, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.put('/business/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        showLoader(false);
        toast.success(response?.data?.message || 'Business updated successfully.');
        await dispatch(getBusiness());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteBusiness = createAsyncThunk('businessSlice/deleteBusiness', async (deleteId, { dispatch, rejectWithValue }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/business/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Business deleted successfully.');
        dispatch(getBusiness());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateBusinessStatus = createAsyncThunk('businessSlice/updateBusinessStatus', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/business/status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getBusiness());
        toast.success(response?.data?.message || 'Business status updated successfully !');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBusinessSubscriptionById = createAsyncThunk(
    'businessSlice/getBusinessSubscriptionById',
    async (id, { rejectWithValue }) => {
        try {
            let response = await axios.get('/business/subscription/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return Promise.resolve(response?.data);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

// Industry Store

export const getIndustryOptions = createAsyncThunk('businessSlice/getIndustryOptions', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get('/industry/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});
export const businessSlice = createSlice({
    name: 'businessSlice',
    initialState: {
        data: [],
        options: [],
        subscription: [],
        industryOptions: []
    },
    extraReducers: (builder) => {
        builder.addCase(getBusiness.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getBusinessSubscriptionById.fulfilled, (state, action) => {
            state.subscription = action?.payload;
            return;
        });
        builder.addCase(getIndustryOptions.fulfilled, (state, action) => {
            state.industryOptions = action?.payload;
            return;
        });
    }
});

export default businessSlice.reducer;
