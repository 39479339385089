// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// API Imports
import axios from '@src/service/axios';
import service from '@src/service/constant';

// import { getPermissionsByToken } from '../service';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

import themeConfig from '@configs/themeConfig';

const initialSetting = () => {
    try {
        var data = JSON.parse(localStorage.getItem('setting'));
        if (data?.logo) themeConfig.app.appLogo = data?.logo;
        if (data?.loader) themeConfig.app.appIcon = data?.loader;
        return data;
    } catch (err) {
        return null;
    }
};

export const getSetting = createAsyncThunk('settingSlice/getSetting', async () => {
    showLoader(true);
    return await axios
        .get('/portal-setting', {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((res) => {
            let data = {
                logo: res.data.data?.logo ? service.API_URL + '/' + res.data.data?.logo : null,
                loader: res.data.data?.loader ? service.API_URL + '/' + res.data.data?.loader : null
            };
            showLoader(false);
            return data;
        })
        .catch((err) => {
            toast.error(err.response?.data?.message || 'Something went wrong!');
            showLoader(false);
            return {};
        });
});

export const settingSlice = createSlice({
    name: 'portalSetting',
    initialState: {
        portalSetting: initialSetting()
    },
    reducers: {
        handleSettingChange: (state, action) => {
            state.portalSetting = action.payload;

            if (action.payload?.logo) themeConfig.app.appLogo = action.payload?.logo;
            if (action.payload?.loader) themeConfig.app.appIcon = action.payload?.loader;

            localStorage.setItem('setting', JSON.stringify(action.payload));
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSetting.fulfilled, (state, action) => {
            settingSlice.caseReducers.handleSettingChange(state, action);
        });
    }
});

export const { handleSettingChange } = settingSlice.actions;

export default settingSlice.reducer;
