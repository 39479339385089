// theme config import
import themeConfig from '@configs/themeConfig';
import { useSelector } from 'react-redux';

const SpinnerComponent = () => {
    const portalSetting = useSelector((state) => state?.portalSetting?.portalSetting?.logo);

    return (
        <div className="fallback-spinner app-loader">
            {/* <img className="fallback-logo" src={themeConfig.app.appIcon} alt="logo" width="auto" height="60px" /> */}
            {portalSetting?.loader && <img className="fallback-logo" src={portalSetting?.loader} alt="logo" height="60px" />}
            <div className="loading">
                <div className="effect-1 effects"></div>
                <div className="effect-2 effects"></div>
                <div className="effect-3 effects"></div>
            </div>
        </div>
    );
};

export default SpinnerComponent;
