// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios
import axios from '@src/service/axios';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getCourse = createAsyncThunk('courseSlice/getCourse', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get('/course?isBussiness=0', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const getCourseById = createAsyncThunk('courseSlice/getCourseById', async (id, { rejectWithValue }) => {
    try {
        let response = await axios.get('/course/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const getCourseAnalyticsById = createAsyncThunk('courseSlice/getCourseAnalyticsById', async (id, { rejectWithValue }) => {
    try {
        let response = await axios.get('/course/analytics/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const getFaqByCourseId = createAsyncThunk('courseSlice/getFaqByCourseId', async (id, { rejectWithValue }) => {
    try {
        let response = await axios.get('/course-FAQ?courseId=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const courseSlice = createSlice({
    name: 'courseSlice',
    initialState: {
        data: [],
        selectedData: {},
        faq: [],
        courseAnalytics: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getCourse.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getCourseById.fulfilled, (state, action) => {
            state.selectedData = action?.payload;
            return;
        });
        builder.addCase(getFaqByCourseId.fulfilled, (state, action) => {
            state.faq = action?.payload;
            return;
        });
        builder.addCase(getCourseAnalyticsById.fulfilled, (state, action) => {
            state.courseAnalytics = action?.payload;
            return;
        });
    }
});

export default courseSlice.reducer;
